import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t, defineMessages } from '@sm/intl';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import { LogoWithText } from '@sm/webassets/Logos';
import { createURL } from '@sm/utils';
import { generateMetricsAttribute } from '@sm/metrics';
import HeroFormCTA, { Props as HeroFormCTAProps } from '~app/components/HeroFormCTA';
import heroImgV1 from '~static/images/dareToAsk/large-dark-text.webp';
import heroImgV2 from '~static/images/dareToAsk/large-light-text.webp';
import { getSrc } from '~app/helpers/assetLoading';
import useStyles from './useStyles';
import ColoredLogoWithText from './ColoredLogoWithText';
import { Button } from '../core/Button';
import { COPY } from '../SimpleLogoHeader';

export const SPLIT_HERO_COPY = defineMessages({
  BUTTON_LABEL: {
    id: 'SplitHero.ButtonLabel',
    defaultMessage: 'Learn more',
    description: '[Type: label][Vis: high] - The text shown in the pages primary CTA',
  },
  IMAGE_LABEL: {
    id: 'SplitHero.ImageLabel',
    defaultMessage: 'SurveyMonkey Branding',
    description: '[Type: label][Vis: low] - The aria text shown in the pages primary image',
  },
});

export type Props = {
  boldHeader: string;
  subHeader: string;
  utSource: string;
  utSource2: string;
  splitHeroTextCentered?: boolean;
  splitHeroDarkBackground?: boolean;
  splitHeroCTA?: boolean;
  splitHeroImage: string;
  formCopy: Omit<HeroFormCTAProps, 'className'>;
};

const SplitHero = ({
  utSource,
  utSource2,
  boldHeader,
  subHeader: subHeaderContent,
  splitHeroTextCentered = false,
  splitHeroDarkBackground = false,
  splitHeroImage,
  splitHeroCTA = false,
  formCopy,
}: Props): React.ReactElement => {
  const {
    imagePlacement,
    ctaForm,
    subHeader,
    bold,
    darkBackground,
    logo,
    strangeBox,
    ctaWrapper,
    headerDesktopPadding,
    headerFontSize,
    subHeaderFontSize,
  } = useStyles();
  const utSource3 = 'SplitHero';

  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const imageVariant = (splitHeroImage === 'dareToAskLightText' ? heroImgV2 : heroImgV1) as string;

  return (
    <>
      {splitHeroTextCentered && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={headerDesktopPadding}
          pt={9}
          pr={3}
          pl={3}
        >
          <ColoredLogoWithText utSource={utSource} utSource2={utSource2} />
          <Box className={headerFontSize}>
            <Typography
              component="section"
              variant="hero1"
              align="center"
              color={splitHeroDarkBackground ? 'light' : 'dark'}
            >
              <span className={bold}>{boldHeader}</span>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box pt={3} className={`${subHeader} ${subHeaderFontSize}`}>
              <Typography
                align="center"
                component="section"
                variant="cardTitle"
                color={splitHeroDarkBackground ? 'light' : 'dark'}
              >
                {subHeaderContent}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={splitHeroDarkBackground ? darkBackground : ''} pt={splitHeroDarkBackground ? 10 : undefined}>
        <Grid noBleeds noGutters>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            {splitHeroCTA ? (
              <Box display="flex" justifyContent="center" pt={7} pb={8} pr={4} pl={4}>
                <Box className={ctaWrapper}>
                  <a
                    aria-label={t(COPY.LOGO_ARIA_LABEL)}
                    data-testid="SplitHero__LogoWithText"
                    href={createURL('/', {
                      ut_source: utSource,
                      ut_source2: utSource2,
                      ut_source3: utSource3,
                      ut_ctatext: 'LogoWithText',
                    })}
                    data-sm-metrics={generateMetricsAttribute({
                      data: {
                        amplitudeEvent: 'endpage user interaction',
                        itemSelected: 'logo',
                        templateType,
                        surveyId,
                        collectorId,
                        respondentId,
                        log: {
                          message: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
                        },
                      },
                    })}
                  >
                    <LogoWithText color="alt" className={logo} />
                  </a>
                  <Box className={`${headerFontSize} ${strangeBox}`} pt={5} pb={3}>
                    <Typography component="section" variant="hero1" color={splitHeroDarkBackground ? 'light' : 'dark'}>
                      <span className={bold}>{boldHeader}</span>
                    </Typography>
                  </Box>
                  <Box pb={7} className={subHeaderFontSize}>
                    <Typography
                      component="section"
                      variant="cardTitle"
                      color={splitHeroDarkBackground ? 'light' : 'dark'}
                    >
                      {subHeaderContent}
                    </Typography>
                  </Box>
                  <Button href="/mp/dare-to-ask/" size="lg">
                    {t(SPLIT_HERO_COPY.BUTTON_LABEL)}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box p={3}>
                <HeroFormCTA className={ctaForm} utSource={utSource} utSource2={utSource2} {...formCopy} />
              </Box>
            )}
          </Grid.Item>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            <Box display="grid" gridTemplateRows="477px" mb={8}>
              <Box display="grid" placeItems="center">
                <img src={getSrc(imageVariant)} alt={t(SPLIT_HERO_COPY.IMAGE_LABEL)} className={imagePlacement} />
              </Box>
            </Box>
          </Grid.Item>
        </Grid>
      </Box>
    </>
  );
};

export default SplitHero;
