import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { LogoWithText } from '@sm/webassets/Logos';
import { createURL } from '@sm/utils';
import { Box } from '@wds/box';
import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { COPY } from '../SimpleLogoHeader';

export type Props = {
  utSource: string;
  utSource2: string;
};

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaQueryMinScreenMD = `@media (min-width: ${theme.breakpoints.md}px)`;
  return {
    logo: {
      height: 24,
      [mediaQueryMinScreenMD]: {
        height: 44,
      },
    },

    // Set global styles to force the LogoWithText to the current Logo Coloring
    '@global': {
      '& div span a': {
        color: theme.brandColors.primary.cleanSlate,
      },
      '& a': {
        '& svg path:last-child': {
          color: theme.brandColors.primary.heritageGreen,
          fill: theme.brandColors.primary.heritageGreen,
        },
        '& svg': {
          '& g': {
            color: theme.brandColors.primary.cleanSlate,
            fill: theme.brandColors.primary.cleanSlate,
            '& path:last-child': {
              color: theme.brandColors.primary.cleanSlate,
              fill: theme.brandColors.primary.cleanSlate,
            },
          },
        },
      },
    },
  };
});

const ColoredLogoWithText = ({ utSource, utSource2 }: Props): React.ReactElement => {
  const { logo } = useStyles();
  const utSource3 = 'ColoredLogoWithText';
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  return (
    <Box pb={5} display="flex" justifyContent="center">
      <a
        aria-label={t(COPY.LOGO_ARIA_LABEL)}
        data-testid="ColoredLogoWithText__LogoWithText"
        href={createURL('/', {
          ut_source: utSource,
          ut_source2: utSource2,
          ut_source3: utSource3,
          ut_ctatext: 'LogoWithText',
        })}
        data-sm-metrics={generateMetricsAttribute({
          data: {
            amplitudeEvent: 'endpage user interaction',
            itemSelected: 'logo',
            templateType,
            surveyId,
            collectorId,
            respondentId,
            log: {
              message: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
            },
          },
        })}
      >
        <LogoWithText className={logo} />
      </a>
    </Box>
  );
};

export default ColoredLogoWithText;
