import React from 'react';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import HeroFormCTA, { Props as HeroFormCTAProps } from '~app/components/HeroFormCTA';

import useStyles from './useStyles';

export type Props = {
  /** Header color (default `dark`) */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'upgrade'
    | 'info'
    | 'muted'
    | 'dark'
    | 'light'
    | 'darkMuted'
    | 'lightMuted'
    | 'link'; // TODO: use `TypographyProps['colors']` once Webassets use latest wrench version
  boldHeader: string;
  subHeader: string;
  utSource?: string;
  utSource2?: string;
  formCopy: Omit<HeroFormCTAProps, 'className'>;
};

const HeroFormCallout = ({
  utSource,
  utSource2,
  color,
  boldHeader,
  subHeader: subHeaderContent,
  formCopy,
}: Props): React.ReactElement => {
  const {
    display,
    wrapperV1,
    imageV1,
    ctaFormV1,
    wrapper,
    imagePlacement,
    image,
    experimentImage1,
    experimentImage2,
    ctaForm,
    header,
    subHeader,
    bold,
  } = useStyles();

  const templateImages = {
    RebrandEpFormImage1: experimentImage1,
    RebrandEpFormImage2: experimentImage2,
  };
  const imageVariant = templateImages?.[utSource2 as keyof typeof templateImages] || image;

  return (
    <>
      {utSource2 === 'RebrandEpFormV1' ? (
        <Box className={display}>
          <Box
            display="flex"
            flexJustify="center"
            flexDirection="column"
            data-testid="HeroFormCallout"
            className={wrapperV1}
          >
            <Box display="flex">
              <HeroFormCTA
                className={ctaFormV1}
                utSource={utSource}
                utSource2={utSource2}
                boldHeader={boldHeader}
                subHeader={subHeaderContent}
                {...formCopy}
              />
              <div className={imageV1} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexJustify="center"
          flexDirection="column"
          data-testid="HeroFormCallout"
          className={wrapper}
        >
          <Box className={header}>
            <Typography component="section" variant="sectionTitle" color={color}>
              <span className={bold}>{boldHeader}</span>
            </Typography>
            <Box pt={3} className={subHeader}>
              <Typography component="div" variant="body" color={color}>
                {subHeaderContent}
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <HeroFormCTA className={ctaForm} utSource={utSource} utSource2={utSource2} {...formCopy} />
            <div className={`${imagePlacement} ${imageVariant}`} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default HeroFormCallout;
